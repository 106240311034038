/* Light mode styles */
.order-book-user-container {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
}

.order-book-user-title-container {
  display: flex;
  justify-content: flex-start;
  padding: 0 8px;
}

.order-book-user-title {
  margin-left: 0 !important;
  font-size: 1rem !important;
}

.order-book-user-table-container {
  height: 100%;
  max-height: 480px;
  overflow-y: auto;
  border-radius: 8px !important;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1) !important;
  background-color: transparent !important;
}

.order-book-user-table {
  min-width: 100px;
  border-collapse: collapse;
  border-spacing: 0px;
  background-color: transparent;
}

.order-book-user-table-cell {
  font-size: 0.7rem !important;
  padding: 2px 4px !important;
  border-bottom: 1px solid #e0e0e0;
  background-color: transparent;
  white-space: nowrap; /* Prevent wrapping */
  color: #333;
}

.order-book-user-table-head-cell {
  font-size: 0.6rem !important;
  padding: 2px 6px !important;
  font-weight: bold !important;
  background-color: transparent !important;
  color: #333;
  white-space: nowrap; /* Prevent wrapping */
}

.order-book-user-table-row {
  &:last-child td, &:last-child th {
    border: 0;
  }
}

.order-book-user-buy-cell {
  color: green !important;
}

.order-book-user-sell-cell {
  color: red !important;
}

/* Dark mode styles */
html.dark .order-book-user-title {
  color: #ddd !important;
}

html.dark .order-book-user-table-container {
  box-shadow: 0 0px 0px rgba(255, 255, 255, 0.1) !important;
}

html.dark .order-book-user-table-cell {
  color: #ddd !important;
  border-bottom: 1px solid #555555 !important;
}

html.dark .order-book-user-table-head-cell {
  color: #ddd !important;
}

html.dark .order-book-user-sell-cell {
  color: #ff6666 !important;
}

html.dark .order-book-user-buy-cell {
  color: #66ff66 !important;
}
