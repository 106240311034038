/* Light mode styles */
:root {
    --background-color: transparent;
    --text-color: #000000;
    --container-bg-color: transparent;
    --icon-color: #000000;
    --selected-tab-color: #FFD700; /* Yellow color for selected tab */
    --unselected-tab-color: #666666; /* Color for unselected tabs */
}

@media (prefers-color-scheme: dark) {
    :root {
        --background-color: #333333;
        --text-color: #ffffff;
        --container-bg-color: #444444;
        --icon-color: #ffffff;
        --selected-tab-color: #FFD700; /* Yellow color for selected tab */
        --unselected-tab-color: #f3efef; /* Color for unselected tabs in dark mode */
    }
}

.user-board {
    min-height: 100vh;
    background: var(--background-color);
    padding: 24px;
}

.container {
    margin-top: 24px;
    background-color: var(--container-bg-color);
    border-radius: 8px;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
    padding: 16px;
}

.user-info {
    display: flex;
    align-items: center;
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    color: var(--icon-color);
}

.user-id {
    margin: 0 8px;
    color: var(--text-color);
}

.copy-button {
    padding: 0;
    margin-left: 0px;
}

.tabs {
    margin-top: 10px;
}

/* Specific styles for tabs */
.MuiTab-root {
    color: var(--unselected-tab-color) !important;
    font-weight: bold;
}

.MuiTab-root.Mui-selected {
    color: var(--selected-tab-color) !important;
}

.MuiTabs-root {
    background-color: var(--container-bg-color);
}

.MuiTabs-indicator {
    background-color: var(--selected-tab-color) !important;
}
