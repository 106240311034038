/* Navbar.css */

/* Common styles */
.navbar-root {
  flex-grow: 1;
}

.navbar {
  background-color: transparent !important; /* Ensure transparent background */
  box-shadow: none !important; /* Ensure no shadow */
}

.navbar-toolbar {
  min-height: 80px !important; /* Set the height of the Navbar */
  display: flex;
  justify-content: space-between;
}

.navbar-logo {
  height: 30px; /* Make the logo's height a percentage of the navbar height */
  margin-right: 10px; /* Add some space between the logo and the title */
}

.navbar-buy-logo {
  height: 25px; /* Adjust the size of the buy logo */
  margin-left: 10px; /* Add some space between the text and the logo */
}

.navbar-title {
  font-weight: 600;
  font-family: 'Poppins', 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  display: flex;
  align-items: center; /* Center the logo and title vertically */
  font-size: 1.5rem; /* Increase the font size */
  text-decoration: none; /* Ensure no underline */
}

.navbar-button {
  font-weight: 700;
  font-family: 'Poppins', 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  margin: 0 10px; /* Add some margin between buttons */
  text-transform: none; /* Ensure text is not all uppercase */
  font-size: 1.1rem; /* Adjust the font size */
  background-color: transparent !important; /* Ensure no background color */
}

.navbar-logo-padding {
  padding: 2px 8px !important;
}

/* Glow effects for light and dark modes */
.navbar-logo-glow-light {
  filter: drop-shadow(0 0 1px rgba(8, 2, 20, 0.8)); /* Light mode glow effect */
}

.navbar-logo-glow-dark {
  filter: drop-shadow(0 0 1px rgba(255, 255, 255, 0.8)); /* Dark mode glow effect */
}

/* Coin selection menu styles */
.coin-selection-menu {
  background-color: transparent; /* Ensure transparent background */
  color: inherit; /* Inherit the text color from the parent */
}

/* Light mode styles */
.light .navbar-title {
  color: #000000; /* Title text color */
}

.light .navbar-button {
  color: #000000 !important; /* Button text color */
}

.light .navbar-buy-here {
  color: #000000; /* Adjust the color as needed */
}

.light .coin-selection-menu-item {
  color: #000000 !important; /* Ensure menu items are black in light mode */
}

.light .MuiSelect-root {
  color: #000000;
}

.light .MuiPaper-root {
  color: #000000 !important;
}

/* Dark mode styles */
.dark .navbar-title,
.dark .navbar-button,
.dark .navbar-buy-here {
  color: #ffffff !important; /* Dark mode text color */
}

.dark .coin-selection-menu-item {
  color: #ffffff !important; /* Ensure menu items are white in dark mode */
}

.dark .MuiSelect-root {
  color: #ffffff;
}

.dark .MuiPaper-root {
  color: #ffffff !important;
}
