:root {
  --table-bg-color: #ffffff;
  --table-header-bg-color: #f5f7fa;
  --table-header-text-color: #333;
  --table-cell-text-color: #000;
  --table-cell-border-color: #e0e0e0;
  --title-text-color: #000;
  --pagination-bg-color: transparent;
  --pagination-text-color: #000;
}

@media (prefers-color-scheme: dark) {
  :root {
    --table-bg-color: #333;
    --table-header-bg-color: #555;
    --table-header-text-color: #fff;
    --table-cell-text-color: #ddd;
    --table-cell-border-color: #555;
    --title-text-color: #fff;
    --pagination-bg-color: transparent;
    --pagination-text-color: #ddd;
  }
}

.tableContainerTrades {
  border-radius: 30px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 400px;
  overflow-y: auto;
  background-color: var(--table-bg-color);
}

.tableHeaderTrades {
  font-size: 1rem;
  font-weight: normal;
  background-color: var(--table-header-bg-color) !important;
  color: var(--table-header-text-color);
  text-align: left;
}

.tableCellTrades {
  border-bottom: 1px solid var(--table-cell-border-color);
  text-align: left;
  color: var(--table-cell-text-color);
}

.tableCellTrades.SELL {
  color: red;
}

.tableCellTrades.BUY {
  color: green;
}

.tableRowTrades {
  &:last-child td,
  &:last-child th {
    border: 0;
  }
}

.titleContainerTrades {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 4px;
}

.titleTrades {
  margin-left: 16px !important;
  font-size: 2rem !important;
  color: var(--title-text-color);
}

/* Pagination styles */
.MuiTablePagination-root {
  background-color: var(--pagination-bg-color) !important;
  color: var(--pagination-text-color) !important;
}

.MuiTablePagination-root .MuiTypography-root {
  color: var(--pagination-text-color) !important;
}

.MuiTablePagination-root .MuiButtonBase-root {
  color: var(--pagination-text-color) !important;
}

/* Dark mode styles */
html.dark .tableContainerTrades {
  background-color: #444444;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
}

html.dark .tableHeaderTrades {
  background-color: #555555 !important;
  color: #ddd;
}

html.dark .tableCellTrades {
  color: #ddd;
  border-bottom: 1px solid #555555 !important;
}

html.dark .tableCellTrades.SELL {
  color: #ff6666;
}

html.dark .tableCellTrades.BUY {
  color: #66ff66;
}

html.dark .titleTrades {
  color: #ddd !important;
}

html.dark .MuiTablePagination-root {
  background-color: var(--pagination-bg-color) !important;
  color: #ddd !important; /* Direct value for debugging */
}

html.dark .MuiTablePagination-root .MuiTypography-root {
  color: #ddd !important;
}

html.dark .MuiTablePagination-root .MuiButtonBase-root {
  color: #ddd !important;
}

html.dark .MuiMenu-paper .MuiMenuItem-root {
  background-color: #333 !important;
  color: #ddd !important;
}

html.dark .MuiMenu-paper .MuiMenuItem-root:hover {
  background-color: #444 !important;
}

html.dark .MuiSelect-icon {
  color: #ddd !important;
}

/* html.dark .MuiPaper-root {
  color: #1d1a1a !important;
} */