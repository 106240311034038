/* Light mode styles */
:root {
    --table-bg-color: #ffffff;
    --table-header-bg-color: #f5f7fa;
    --table-header-text-color: #333;
    --table-cell-border-color: #e0e0e0;
    --title-text-color: #000;
  }
  
  @media (prefers-color-scheme: dark) {
    :root {
      --table-bg-color: #333;
      --table-header-bg-color: #555;
      --table-header-text-color: #fff;
      --table-cell-border-color: #555;
      --title-text-color: #fff;
    }
  }
  
  /* .tableContainerLeaderboardHolders {
    border-radius: 30px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 800px;
    overflow-y: auto;
    background-color: var(--table-bg-color);
  } */
  
  .tableHeaderLeaderboardHolders {
    font-size: 1rem; /* Set a bigger font size */
    font-weight: normal; /* Remove the bold weight */
    background-color: var(--table-header-bg-color) !important; /* Different background color for the header */
    color: var(--table-header-text-color);
    text-align: left; /* Align headers to the left */
  }
  
  .tableCellLeaderboardHolders {
    border-bottom: 1px solid var(--table-cell-border-color);
    text-align: left; /* Align content to the left */
  }
  
  .tableRowLeaderboardHolders {
    &:last-child td,
    &:last-child th {
      border: 0;
    }
  }
  
  .titleContainerLeaderboardHolders {
    display: flex;
    justify-content: flex-start; /* Adjust title position */
    margin-bottom: 16px; /* Space between title and table */
  }
  
  .titleLeaderboardHolders {
    margin-left: 16px; /* Adjust title position */
    font-size: 2rem !important; /* Increase the font size */
    color: var(--title-text-color);
  }
  
  /* Dark mode styles */
  html.dark .tableContainerLeaderboardHolders {
    background-color: #444444;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
  }
  
  html.dark .tableHeaderLeaderboardHolders {
    background-color: #555555 !important;
    color: #ddd;
  }
  
  html.dark .tableCellLeaderboardHolders {
    color: #ddd;
    border-bottom: 1px solid #555555 !important;
  }
  
  html.dark .titleLeaderboardHolders {
    color: #ddd !important;
  }
  