/* Light mode styles */
.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .titleContainer {
    display: flex;
    justify-content: flex-start;
  }
  
  .title {
    margin-left: 10px !important;
    font-size: 1.5rem !important;
    color: #000;
  }
  
  .tableContainer {
    flex-grow: 1;
    overflow: auto;
    border-radius: 15px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  .table {
    min-width: 300px;
  }
  
  .tableCell {
    font-size: 0.8rem !important;
    padding: 4px 8px !important;
    border-bottom: 1px solid #e0e0e0;
    color: #333;
  }
  
  .tableHeadCell {
    font-size: 0.9rem !important;
    padding: 8px 16px !important;
    font-weight: bold !important;
    background-color: #f5f7fa !important;
    color: #333;
  }
  
  .sellCell {
    color: red !important;
  }
  
  .buyCell {
    color: green !important;
  }
  
  .tableRow {
    &:last-child td,
    &:last-child th {
      border: 0;
    }
  }
  
  /* Dark mode styles */
  html.dark .title {
    color: #ddd !important;
  }
  
  html.dark .tableContainer {
    background-color: #444444;
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
  }
  
  html.dark .tableCell {
    color: #ddd;
    border-bottom: 1px solid #555555 !important;
  }
  
  html.dark .tableHeadCell {
    background-color: #555555 !important;
    color: #ddd;
  }
  
  html.dark .sellCell {
    color: #ff6666 !important;
  }
  
  html.dark .buyCell {
    color: #66ff66 !important;
  }
  