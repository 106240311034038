@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
body {
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  font-family: 'Poppins', 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Dark mode styles */
.dark body {
  background: linear-gradient(135deg, #080214  0%, #002744  100%);
  color: #ffffff;
}
