.stats-container {
    margin-top: 20px;
}

.stat-item {
    text-align: center;
    margin-bottom: 10px;
}

.stat-value {
    font-size: 1.3rem !important;
    font-weight: normal;
}

.stat-label {
    font-size: 0.8rem !important;
    color: #777;
}

.title {
    margin-bottom: 20px;
}

.date-picker {
    margin-bottom: 20px;
}

.date-picker input {
    color: inherit;
}

.light .date-picker input {
    color: #000000;
    /* background-color: #ffffff; */
    background-color: transparent important!;
}

.light .date-picker label {
    color: #000000;
    background-color: transparent important!;
}

.dark .date-picker input {
    color: #ffffff;
    background-color: transparent important!;
}

.dark .date-picker label {
    color: #ffffff;
}

.light .MuiInputBase-root.MuiOutlinedInput-root {
    border-color: #cccccc;
}

.light .MuiInputBase-root.MuiOutlinedInput-root:hover {
    border-color: #999999;
} 

.light .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
    border-color: #1976d2;
}

.dark .MuiInputBase-root.MuiOutlinedInput-root {
    border-color: #555555;
}

.dark .MuiInputBase-root.MuiOutlinedInput-root:hover {
    border-color: #777777;
} 

.dark .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
    border-color: #90caf9;
}

.MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #FFC600;
}
/* 
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    border: 1px solid;
    transition: border-color 0.3s;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover {
    border-color: inherit;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused {
    border-color: inherit;
}  */

.titleTrades {
    margin-bottom: 20px !important;
}

.date-picker-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.date-picker {
    display: flex;
    gap: 20px;
}