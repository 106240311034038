/* Light mode styles */
.home-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column; /* Stacks elements vertically */
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
}

.background-animation {
  position: relative;
  width: 100%;
  height: 500px;
  pointer-events: none; /* Ensure it does not capture clicks */
  z-index: 1; /* Lower z-index to place it behind the content */
}

.content-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Higher z-index to ensure it stays on top */
  margin-top: -50px; /* Adjust margin to move content up */
}

.text-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.glossy-text {
  margin-bottom: 20px;
  text-shadow: 4px 4px 10px rgba(0, 0, 100, 0.5);
  position: relative;
  color: #747272;
  font-size: 2.5rem;
  font-weight: bold !important;
  animation: shine 2s infinite !important;
}

.form-container {
  display: flex;
  align-items: center;
}

.form-control {
  min-width: 200px;
  margin-right: 20px !important; /* Increased space between menu item and button */
}

.form-control-label {
  color: #000;
}

.select-menu {
  color: #000;
  border-color: #fff;
}

.select-menu-paper {
  background-color: rgba(255, 255, 255, 0.9);
  color: #000;
}

.select-menu-item {
  height: 40px;
  display: flex;
  align-items: center;
}

.coin-logo {
  width: 20px;
  margin-right: 10px;
}

.enter-button {
  color: #fff;
  background-color: 'primary'; /* Default button color */
}

/* Dark mode styles */

.dark .text-container {
  color: #fff;
}

.dark .glossy-text {
  text-shadow: 4px 4px 10px rgba(255, 255, 255, 0.5);
  color: #e0e0e0;
}

.dark .form-control-label {
  color: #fff;
}

.dark .select-menu {
  color: #fff;
  border-color: #666;
}

.dark .select-menu-paper {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
}

.dark .enter-button {
  background-color: 'primary !important'; /* Dark mode button color */
  color: #221b1b !important;
}

/* Shine animation */
@keyframes shine {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}
