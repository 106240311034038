.user-id-input-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.user-id-input {
    margin-right: 10px !important; /* Add space between the text field and the button */
}

/* Light mode styles */
.user-id-input-container.light {
    color: #000;
}

.user-id-input.light {
    /* background-color: #fff; */
    color: #000;
}

.text-field-input.light {
    color: #000; /* Text color inside the text field */
}

.submit-button.light {
    background-color: #007bff;
    color: #fff;
    opacity: 0.7;
    font-size: 0.75rem; /* Make button text smaller */
    padding: 4px 8px; /* Reduce button padding */
}

/* Dark mode styles */
.user-id-input-container.dark {
    color: #ddd;
}

.user-id-input.dark {
    /* background-color: #333; */
    color: #fff; /* Change text color to white in dark mode */
}

.text-field-input.dark {
    color: #fff; /* Text color inside the text field in dark mode */
}

.submit-button.dark {
    background-color: #685e28;
    color: #ffffff;
    opacity: 0.7;
    font-size: 0.75rem; /* Make button text smaller */
    padding: 4px 8px; /* Reduce button padding */
}
