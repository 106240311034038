/* Light mode styles */
:root {
    /* --background-color: #ffffff; */
    --text-color: #000000;
    --line-color: #2962FF;
    --top-color: #2962FF;
    --bottom-color: rgba(41, 98, 255, 0.28);
}

html.dark {
    /* --background-color: #333333; */
    --text-color: #ffffff;
    --line-color: #1e90ff;
    --top-color: #1e90ff;
    --bottom-color: rgba(30, 144, 255, 0.28);
}

.chart-container {
    height: 100%;
    position: relative;
    background-color: var(--background-color);
    color: var(--text-color);
}

.chart-title {
    margin-top: 25px;
}

.chart-title img {
    height: 40px;
    margin-right: 10px;
    vertical-align: middle;
}

.chart-content {
    height: calc(100% - 50px);
    position: relative;
    z-index: 0;
}

.price-label {
    position: absolute;
    top: 85px;
    left: 20px;
    background-color: transparent;
    color: var(--text-color);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    z-index: 1;
    pointer-events: auto;
}

.price-label img {
    width: 20px;
    height: 20px;
    margin-left: 0px;
}

.icon-container {
    position: absolute;
    top: 35px;
    right: 90px;
    display: flex;
    gap: 10px;
    z-index: 1;
    pointer-events: auto;
}

.icon-container img {
    width: 20px;
    height: 20px;
}
