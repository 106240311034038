:root {
    --price-chart-text-color: #000000;
    /* --price-chart-background-color: #ffffff; */
    --price-chart-grid-color: rgba(197, 203, 206, 0.8); /* Light mode grid color */
    --price-chart-button-background-color: #f0f0f04f;
    --price-chart-button-text-color: #000000;
    --price-chart-label-background-color: transparent;
    --price-chart-label-text-color: black;
}

html.dark {
    --price-chart-text-color: #ffffff;
    /* --price-chart-background-color: #333333; */
    --price-chart-grid-color: rgba(255, 255, 255, 0.1); /* Dark mode grid color */
    --price-chart-button-background-color: #4444446c;
    --price-chart-button-text-color: #ffffff;
    --price-chart-label-background-color: transparent;
    --price-chart-label-text-color: white;
}

.price-chart-layout {
    height: 100%;
    position: relative;
    padding-right: 0px;
    background-color: var(--price-chart-background-color);
    color: var(--price-chart-text-color);
}

.price-chart-layout.dark {
    background-color: var(--price-chart-background-color);
    color: var(--price-chart-text-color);
}

.price-chart-header {
    position: absolute;
    top: 10px;
    left: 20px;
    z-index: 3;
    display: flex;
    align-items: center;
}

.price-chart-logo {
    height: 30px;
    margin-right: 10px;
}

.price-chart-title {
    margin: 0;
    color: var(--price-chart-text-color);
}

.price-chart-buttons {
    position: absolute;
    top: 20px;
    right: 70px;
    z-index: 3;
}

.price-chart-button {
    background-color: var(--price-chart-button-background-color) !important;
    color: var(--price-chart-button-text-color) !important;
}

.price-chart-price {
    position: absolute;
    top: 45px;
    left: 20px;
    z-index: 3;
}

.price-chart-label {
    display: flex;
    align-items: center;
    background-color: var(--price-chart-label-background-color);
    color: var(--price-chart-label-text-color);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 1.1rem;
    z-index: 3; /* Ensure the price label and button are above the chart */
    pointer-events: auto; /* Ensure pointer events are enabled */
    white-space: nowrap; /* Prevent line breaks */
    top: 0px;
    left: 0px;
}

.price-chart-switch-button {
    margin-left: 0px;
}

.price-chart-switch-icon {
    width: 15px;
    height: 15px;
}

.price-chart-box {
    height: 100%;
    position: relative;
    z-index: 1;
}
