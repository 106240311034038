/* TotalPortfolioChart.css */
:root {
    --background-color: transparent !important;
    --text-color: #000000;
    --line-color: #2962FF;
    --top-color: rgba(41, 98, 255, 0.5);
    --bottom-color: rgba(41, 98, 255, 0.2);
}

html.dark {
    --background-color: transparent !important;
    --text-color: #ffffff;
    --line-color: #1e90ff;
    --top-color: rgba(30, 144, 255, 0.5);
    --bottom-color: rgba(30, 144, 255, 0.2);
}

.chart-container {
    height: 100%;
    position: relative;
    background-color: var(--background-color);
    color: var(--text-color);
}
