/* Light mode styles */
.my-last-offers-container {
    padding: 0 !important;
    margin: 0 !important;
    height: 100%;
  }
  
  .my-last-offers-title-container {
    display: flex;
    justify-content: flex-start;
    padding: 0 8px;
  }
  
  .my-last-offers-title {
    margin-left: 0 !important;
    font-size: 1rem !important;
  }
  
  .my-last-offers-table-container {
    height: 100%;
    max-height: 420px;
    overflow-y: auto;
    border-radius: 8px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: transparent !important;
  }
  
  .my-last-offers-table {
    min-width: 100px;
    border-collapse: collapse; /* Remove extra borders */
    border-spacing: 0px; /* Remove extra spacing */
    background-color: transparent;
  }
  
  .my-last-offers-table-cell {
    font-size: 0.7rem !important;
    padding: 2px 4px !important;
    border-bottom: 1px solid #e0e0e0;
    background-color: transparent;
    white-space: nowrap; /* Prevent wrapping */
    color: #333;
  }
  
  .my-last-offers-table-head-cell {
    font-size: 0.7rem !important;
    padding: 2px 6px !important;
    font-weight: bold !important;
    background-color: transparent !important;
    color: #333;
    white-space: nowrap; /* Prevent wrapping */
  }
  
  .my-last-offers-table-row {
    &:last-child td, &:last-child th {
      border: 0;
    }
  }
  
  .my-last-offers-buy-cell {
    color: green !important;
  }
  
  .my-last-offers-sell-cell {
    color: red !important;
  }
  
  /* Dark mode styles */
  html.dark .my-last-offers-title {
    color: #ddd !important;
  }
  
  html.dark .my-last-offers-table-container {
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
  }
  
  html.dark .my-last-offers-table-cell {
    color: #ddd !important;
    border-bottom: 1px solid #555555 !important;
  }
  
  html.dark .my-last-offers-table-head-cell {
    color: #ddd !important;
  }
  
  html.dark .my-last-offers-sell-cell {
    color: #ff6666 !important;
  }
  
  html.dark .my-last-offers-buy-cell {
    color: #66ff66 !important;
  }
  